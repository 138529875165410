export default (price, locale, currency, pricingDecimals=null) => {
    let formatPrice = "$"+price;
    if(window.Intl && typeof window.Intl.NumberFormat === "function"){
      formatPrice = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(price)
      if( Number.isInteger(parseInt(pricingDecimals)) && parseInt(pricingDecimals) >= 0){
        formatPrice = Intl.NumberFormat(locale, {
          style: "currency",
          currency: currency,
          maximumFractionDigits: parseInt(pricingDecimals)
        }).format(price)
      }
      
    }
    return formatPrice
  }