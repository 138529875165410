import React from "react"

export default function Hr() {
    return (
      <div style={{ position: 'relative', height: 10 }}>
        <div
          style={
            {
              "backgroundColor": "rgba(175,175,175,0.34)",
              "bottom": 0,
              "content": "''",
              "display": "block",
              "height": "1px",
              "left": "0",
              margin: '5px 0',
              "position": "absolute",
              "WebkitTransition": "background-color 0.3s cubic-bezier(0.3, 0, 0, 1)",
              "transition": "background-color 0.3s cubic-bezier(0.3, 0, 0, 1)",
              "width": "100%",
              "zIndex": "4",
            }
          }
        />
      </div>
    )
  }